<template>
  <Layout class="layout-container">
    <Header class="layout-header" :style="{ backgroundImage: `url(${img.headerBg})` }">
      <div class="layout-header-side">
        <div class="header-time-area">
          <div style="height: 20px">{{ date }}</div>
          <!-- {{weeks[week]}} -->
          <div style="height: 30px">{{ time }}</div>
        </div>
        <div class="header-left-buttons">
          <div :class="currentItem == 'home' ? 'header-button-item active' : 'header-button-item'" @click="switchMenu('home')">
            {{ showLang('com.head.home') }}<img :src="img.headerButtonActive" alt="" v-if="currentItem == 'home'" />
          </div>
          <div :class="currentItem == 'light' ? 'header-button-item active' : 'header-button-item'" @click="switchMenu('light')">
            {{ showLang('com.head.lighting') }}<img :src="img.headerButtonActive" alt="" v-if="currentItem == 'light'" />
          </div>
          <div :class="currentItem == 'lux' ? 'header-button-item active' : 'header-button-item'" @click="switchMenu('lux')">
            {{ showLang('com.head.intelligence') }}<img :src="img.headerButtonActive" alt="" v-if="currentItem == 'lux'" />
          </div>
          <div :class="currentItem == 'gis' ? 'header-button-item active' : 'header-button-item'" @click="switchMenu('gis')">
            GIS<img :src="img.headerButtonActive" alt="" v-if="currentItem == 'gis'" />
          </div>
        </div>
      </div>
      <div class="layout-header-center">
        <img v-if="config && config.logo" :src="`//${domains.trans}/station/file/logo?id=${config.logo}&auth=${token}`" :title="config.name" />
      </div>
      <div class="layout-header-side">
        <div class="header-right-buttons">
          <div :class="currentItem == 'order' ? 'header-button-item active' : 'header-button-item'" @click="switchMenu('order')">
            {{ showLang('com.head.devOps') }}<img :src="img.headerButtonActive" alt="" v-if="currentItem == 'order'" />
          </div>
          <div v-if="!domains.front" :class="currentItem == 'asset' ? 'header-button-item active' : 'header-button-item'" @click="switchMenu('asset')">
            {{ showLang('com.head.property') }}<img :src="img.headerButtonActive" alt="" v-if="currentItem == 'asset'" />
          </div>
          <div v-if="!domains.front" :class="currentItem == 'analyse' ? 'header-button-item active' : 'header-button-item'" @click="switchMenu('analyse')">
            {{ showLang('com.head.data') }}<img :src="img.headerButtonActive" alt="" v-if="currentItem == 'analyse'" />
          </div>
          <div v-if="!domains.front" :class="currentItem == 'system' ? 'header-button-item active' : 'header-button-item'" @click="switchMenu('system')">
            {{ showLang('com.head.system') }}<img :src="img.headerButtonActive" alt="" v-if="currentItem == 'system'" />
          </div>
          <div class="header-user-info" v-if="$router.currentRoute.path != '/index/light/road/indexHome'">
            <img style="width: 30px; height: 30px; margin-right: 8px" :src="img.headerUserIcon" />
            <HeaderDropDown />
          </div>
        </div>
      </div>
    </Header>
    <Content class="layout-content">
      <router-view></router-view>
    </Content>
    <ModalCommandAsk v-model="showAskModal" />
    <ModalCommandAskList v-model="showAskModalList" />
    <div class="float-ask-icon" @click="showAskModals" v-if="cmdAskModalContent.length">
      <Icon type="md-cube" size="45" color="red" />
    </div>
  </Layout>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import HeaderDropDown from '@/views/common/menu/HeaderDropDown'
import ModalCommandAsk from '@/views/common/modals/ModalCommandAsk'
import ModalCommandAskList from '@/views/common/modals/ModalCommandAskList'
export default {
  name: 'Home',
  components: {
    HeaderDropDown,
    ModalCommandAsk,
    ModalCommandAskList
  },
  data() {
    return {
      date: '',
      time: '',
      week: '',
      currentItem: 'home', //light, video, vehicle, asset, order, analyse, system
      urls: {
        home: '/index/light/road/home', ///index/lightindex
        light: '/index/light/road/monitor',
        gis: '/index/light/road/gis',
        lux: '/index/light/road/lux',
        asset: '/index/light/road/asset',
        order: '/index/light/road/work',
        analyse: '/index/light/road/analyse',
        system: '/index/light/road/system'
      },
      timeout: null,
      showAskModal: false,
      showAskModalList: false
    }
  },
  computed: {
    ...mapGetters('auth', ['custAndType', 'showLang']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue']),
    ...mapState('common', ['weeks']),
    ...mapState('cmd', ['cmdAskModalRefresh', 'cmdAskModalContent'])
  },
  watch: {
    cmdAskModalRefresh() {
      // console.log('cmd ask modal refresh', this.cmdAskModalRefresh, this.cmdAskModalContent)
      this.showAskModalList = true
    },
    $route: {
      handler: function(route) {
        this.query = route.query
        for (let key in this.urls) {
          let url = this.urls[key]
          if (url.indexOf(route.path) != -1) {
            this.currentItem = key
            return
          }
        }
      },
      immediate: true
    },
    agentChangeValue() {
      this.debounceInitData()
    }
  },
  created: function() {},
  mounted: function() {
    console.log('index mounted', this.domains)
    this.setDateTime()
  },
  methods: {
    showAskModals: function() {
      // console.log('open ask modal list', this.showAskModalList)
      this.showAskModalList = !this.showAskModalList
    },
    debounceInitData: function() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.initData, 500)
    },
    setDateTime: function() {
      let now = new Date()
      this.date = now.format('yyyy-MM-dd')
      this.time = now.format('HH:mm:ss')
      this.week = now.getDay()
      setTimeout(this.setDateTime, 1000)
    },
    initData: function() {
      if (this.customerId == 0) return
      this.$store.dispatch('common/getConnectTypes')
      this.$store.dispatch('common/getAlarmTypes')
      this.$store.dispatch('group/getGroupAndStations', 0)
    },
    switchMenu: function(menu) {
      if(this.$router.currentRoute.path=="/index/light/road/indexHome"){//判断只展示首页
        return false;
      }
      if (this.currentItem == menu) return
      this.currentItem = menu
      let url = this.urls[menu]
      if (url && url.length > 0) {
        this.$router.push(url)
      }
    }
  }
}
</script>
<style scoped>
@keyframes test {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}
.float-ask-icon {
  width: 50px;
  height: 50px;
  border: solid 1px rgba(190, 186, 186, 0.582);
  border-radius: 12px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: test 1000ms infinite;
}
.factory-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* border: solid 1px red; */
  display: flex;
  flex-direction: column;
  /* line-height: 0; */
}
.factory-header {
  height: 110px;
  flex: none;
  /* border: solid 1px red; */
  display: flex;
  /* align-items: flex-end; */
  padding: 0;
  background-color: #011220;
}
.factory-content {
  height: 10px;
  flex: auto;
  /* border: solid 1px red; */
}
.factory-content div {
  height: 100%;
}
.layout-header-side {
  width: 650px;
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
}
.header-time-area {
  position: absolute;
  left: 30px;
  top: 5px;
  height: 80px;
  width: 140px;
  flex: none;
  /* border: solid 1px red; */
  display: flex;
  /* padding: 10px; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.header-content-area {
  height: 0;
  flex: auto;
  display: flex;
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;
  height: 50px;
}
.header-line-area {
  height: 43px;
  flex: none;
  text-align: right;
}
.header-button-area {
  width: 0;
  flex: auto;
  /* border: solid 1px red; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.header-button-right {
  width: 0;
  flex: auto;
  /* border: solid 1px red; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.layout-header-center {
  width: 0;
  flex: auto;
  text-align: center;
  /* border: solid 1px red; */
}
.layout-header-center img {
  width: 100%;
  max-width: 700px;
  height: 100%;
  max-height: 92px;
}
.header-user-info {
  /* border: solid 1px red; */
  width: 150px;
  flex: none;
  /* margin: auto 9px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.layout-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* line-height: 0; */
}
.layout-header {
  height: 92px;
  flex: none;
  display: flex;
  padding: 0;
  background-repeat: no-repeat;
  background-color: #010b16;
  background-position: top center;
  background-size: 100% 80px;
}
.layout-header-side {
  width: 610px;
  flex: none;
  position: relative;
  /* border: solid 1px red; */
}

.header-left-buttons {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  /* padding-right: 0; */
  /* border: solid 1px red; */
  padding-bottom: 5px;
}
.header-right-buttons {
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: flex-end; */
  padding-left: 9px;
  padding-bottom: 5px;
}
.header-user-info {
  /* border: solid 1px red; */
  flex: auto;
  /* margin: auto 9px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-button-item {
  width: 92px;
  height: 44px;
  text-align: center;
  line-height: 44px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  margin: auto 9px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
}
.header-button-item.active {
  color: #20c3ff;
  /* text-shadow: 0px 0px 29px #01f1ff; */
  background-image: url('../../../assets/img/header-activeBg.png');
  background-size: 100%;
  background-position: center;
}
.header-button-item img {
  position: absolute;
  width: 170px;
  height: 16px;
  left: -36px;
  top: 49px;
}
.layout-header-center {
  width: 0;
  flex: auto;
  text-align: center;
  /* border: solid 1px red; */
}
.layout-content {
  height: 10px;
  flex: auto;
  align-items: stretch;
  display: flex;
  /* height: calc(100% - 65px); */
  /* overflow: hidden; */
  background: #4c5e90;
  overflow: auto;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.layout-content::-webkit-scrollbar {
  display: none;
}
</style>
